<template>
    <div class="discover-page">
        <div class="dicover-header">
            <div class="discover-header-icon"> </div>
            <div class="dicover-header-text">关于中国农业银行14:00之后暂关闭代扣的通知</div>
        </div>
        <div class="dicover-main"> 
            <div class="dicover-main-header">热门产品</div>
            <div class="dicover-mian-card">
                <div class="dicover-main-card-cont">
                    <div class="card-top">
                        <img class="card-img" src="" alt="产品图" />
                        <div class="card-name">拍拍贷</div>
                    </div>
                    <div class="card-center">
                        <div class='card-center-left'>20000 ~ 20万</div>  
                        <div class='card-center-right'>立即借钱</div> 
                         
                    </div>
                    <!-- <div class="card-conter-bottom">大品牌 大额低息</div> -->
                    <div class="card-conter-bottom-m">
                         <div>利率：0.04%/日</div>
                         <div class="card-bottom-left">期限：30-360日</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="less" src="./index.less" scoped></style>
<script>
import { onMounted, toRefs,reactive } from "vue"
import APP from '../../utils/APP'
import API from '../../server/api'


export default ({
    setup() {
        const state=reactive({
           
            
        })
      
        return{
            ...toRefs(state),
          
        }
    },
})
</script>
